<template>
  <div class="modal-container vue-modal" v-if="show">
    <div class="modal-black-cover"></div>
    <div class="modal-content-layout">
      <div class="modal-content-container px-6 py-10 flex flex-col justify-center items-center">
        <!-- 標題部分 -->
        <div class="title" v-if="title">
          <span class="text-20px font-medium pb-2 border-b-4 border-orange-10">
            {{ title }}
          </span>
        </div>
 
        <!-- 內容部分 -->
        <div class="my-6" v-html="content" @click="handleCancel"></div>

        <!-- 按鈕組 -->
        <button 
          class="btn"
          :class="confirmButtonClass"
          @click="handleCancel"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
    confirmButtonClass: {
      type: String,
      default: 'btn-primary w-full'
    }
  },
  emits: ['close-modal'],

  methods: {
    handleCancel() {
      this.$emit('close-modal');
    },
  }
});
</script>
