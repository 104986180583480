<template>
  <div>
    <div class="grid grid-cols-[33fr_47fr_87fr_40fr] rounded-8px bg-grey-2">
      <!-- 縮圖列 -->
      <div class='p-2'>
        <div class="rounded-8px overflow-hidden relative">
          <div v-if="post.phrozen_verified" class="w-5 h-5 flex items-center justify-center absolute top-0 bg-red rounded-br-md text-14px bg-[#E60044]">
            <img src="../../../images/home/phrozen_verified.svg" class="w-4 h-4 brightness-[4]" />
          </div>
          <img :src="post.cover_image_src" :alt="post.title" />
        </div>
      </div>
      <!-- 標題列 -->
      <div class='py-2'>
        <div class="flex flex-col justify-between h-full pr-3 break-word">
          <div class="post_title text-14px font-medium hover:underline">
            <template v-if="post.verified_state == 'progress'">
              <span class="cursor-pointer" @click="$emit('post-in-progress', post)">
                {{ post.title }}
              </span>
            </template>
            <template v-else>
              <a :href="`/profile/models/${post.id}/edit`" class="">
                {{ post.title }}
              </a>
            </template>
          </div> 
        </div>
      </div>

      <!-- 詳細資訊列 -->
      <div class='py-2'>
        <div class="max-h-[116px] h-full">
          <!-- 提交日期 -->
          <div class="submit_date">
            {{ $t('models.phrozen_verified.submit_date') }}:
            {{ formatSubmitDate }}
          </div>

          <!-- 接受日期 -->
          <template v-if="post.verified_state !== 'init'">
            <div class="submit_date">
              {{ $t('phrozen_verified.index.acceptance_date') }}:
              {{ formatAcceptanceDate }}
            </div>
          </template>
          <!-- 完成日期或預計完成日期 -->
          <template v-if="post.verified_state === 'completed'">
            <div class="completion_date">
              {{ $t('models.phrozen_verified.completion_date') }}:
              {{ formatCompletionDate }}
            </div>
          </template>
          <template v-else-if="post.verified_state !== 'progress'">
            <div class="estimated_complete_date">
              {{ $t('models.phrozen_verified.estimated_complete_date') }}:
              -
            </div>
          </template>
          <template v-else>
            <div class="estimated_complete_date">
              {{ $t('models.phrozen_verified.estimated_complete_date') }}:
              <template v-if="isEstimatedDateValid">
                {{ formatEstimatedDateRange }}
              </template>
              <template v-else>
                <span class='text-warning font-medium'>
                  {{ formatEstimatedDateRange }}
                </span>
                <div class="flex items-center">
                  <img src="../../../images/alert-warning.svg" class="h-5 w-5 mr-1" />
                  <span class='text-12px text-grey-5 font-medium'>
                    {{ $t('phrozen_verified.index.deadline_extended') }}
                  </span>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <!-- 狀態列 -->
      <div class='py-2'>
        <div class="flex flex-col justify-center items-center h-full">
            <div class="w-4 h-4 rounded-full my-1" :style="{ background: `var(--color-post-state-${post.verified_state.replace(/_/g, '-')})` }"></div>
          <div class="state text-14px font-medium text-center">
            {{ $t(`post.state.${post.verified_state}`) }}
          </div>
          <a v-if="post.verified_state === 'init'" 
             href="#" 
             class="min-w-14 btn btn-primary mt-2 py-1 px-2 text-12px"
             @click.prevent="showDisclaimer"
             v-bind="isSubmitting ? { disabled: true } : {}">
             {{ isSubmitting ? $t('post.state.pending') : $t('models.index.submit') }}
          </a>
          <a v-else-if="post.verified_state === 'pending'" 
             href="#" 
             class="btn btn-primary mt-2 py-1 px-2 text-12px" 
             @click.prevent="handleCancel"
             v-bind="isSubmitting ? { disabled: true } : {}">
             {{ isSubmitting ? $t('post.state.pending') : $t('models.index.cancel') }}
          </a>
        </div>
      </div>
    </div>
    <hr v-if="!isLast" class='border-grey-3 my-2'>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    hasAcceptedDisclaimer: {
      type: Boolean,
      default: false
    }
  },
  emits: ['post-in-progress', 'pv-post-cancel', 'show-disclaimer'],
  setup(props, { emit }) {
    const mayAskForSubmit = computed(() => {
      return props.post.state === 'draft' || props.post.state === 'submit_rejection'
    })

    const formatDate = (date) => {
      if (!date) return '-';
      return new Date(date).toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '/');
    };

    const isInitOrPending = computed(() => 
      props.post.verified_state === 'init' || props.post.verified_state === 'pending'
    );

    const formatSubmitDate = computed(() => 
      formatDate(props.post.submitted_verified_at)
    );

    const formatAcceptanceDate = computed(() => 
      formatDate(props.post.verified_accepted_at)
    );

    const formatCompletionDate = computed(() => 
      formatDate(props.post.verified_completed_at)
    );

    const estimatedDateRange = computed(() => {
      const submitAt = props.post.verified_accepted_at 
        ? new Date(props.post.verified_accepted_at) 
        : new Date();
      
      const startAt = new Date(submitAt);
      startAt.setDate(startAt.getDate() + 7);
      
      const endAt = new Date(submitAt);
      endAt.setDate(endAt.getDate() + 21);

      return { startAt, endAt };
    });

    const formatEstimatedDateRange = computed(() => {
      const { startAt, endAt } = estimatedDateRange.value;
      return `${formatDate(startAt)} - ${formatDate(endAt)}`;
    });

    const isEstimatedDateValid = computed(() => {
      const { endAt } = estimatedDateRange.value;
      return endAt > new Date();
    });

    const handleCancel = () => {
      emit('pv-post-cancel', props.post.id);
    };

    const showDisclaimer = () => {
      emit('show-disclaimer', props.post.id);
    };

    return {
      isInitOrPending,
      formatSubmitDate,
      formatAcceptanceDate,
      formatCompletionDate,
      estimatedDateRange,
      formatEstimatedDateRange,
      isEstimatedDateValid,
      formatDate,
      handleCancel,
      showDisclaimer,
      mayAskForSubmit
    };
  }
});
</script>
