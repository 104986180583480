<template>
  <div class="modal-container" v-if="show">
    <div class="modal-black-cover"></div>
    <div class="modal-content-layout">
      <div class="modal-content-container p-8 pb-4">
        <!-- 標題部分 -->
        <div class="title">
          <span class="text-orange-10 text-20px font-medium">
            {{ $t('phrozen_verified.disclaimer.pv_service') }}
          </span>
          <span class='text-14px'>
            - {{ $t('phrozen_verified.disclaimer.pv_service_content') }}
          </span>
        </div>

        <!-- 服務內容列表 -->
        <ul class='mt-4 mb-8 pl-[14px] list-disc text-16px'>
          <li>{{ $t('phrozen_verified.disclaimer.pv_service_content_part1') }}</li>
          <li>{{ $t('phrozen_verified.disclaimer.pv_service_content_part2') }}</li>
          <li>{{ $t('phrozen_verified.disclaimer.pv_service_content_part3') }}</li>
        </ul>

        <!-- 免責聲明部分 -->
        <div class="title">
          <span class="text-orange-10 text-20px font-medium">
            {{ $t('phrozen_verified.disclaimer.disclaimer') }}
          </span>
        </div>
        <div class="text-16px mt-4 mb-8" v-html="disclaimerContent"></div>

        <!-- 確認勾選框 -->
        <div class="my-8">
          <input 
            :id="'checkbox_' + postId" 
            v-model="isAgreed"
            class='hidden' 
            type="checkbox"
          >
          <label :for="'checkbox_' + postId" class='flex items-center'>
            <div class="icon w-6 h-6 inline-block mr-2"></div>
            <span class="italic text-grey-8">
              {{ $t('phrozen_verified.disclaimer.aware_policies') }}
            </span>
          </label>
        </div>

        <!-- 按鈕組 -->
        <div class="grid grid-cols-2 space-x-2">
          <button 
            class="btn btn-secondary"
            @click="handleCancel"
          >
            {{ $t('phrozen_verified.disclaimer.cancel') }}
          </button>
          <button 
            class="btn btn-primary"
            :disabled="!isAgreed"
            @click="handleConfirm"
          >
            {{ $t('phrozen_verified.disclaimer.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PhrozenVerifiedDisclaimer',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    postId: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['close-disclaimer', 'confirm-disclaimer'],
  
  data() {
    return {
      isAgreed: false
    }
  },

  computed: {
    disclaimerContent() {
      return this.$t('phrozen_verified.disclaimer.content', {
        verified_price: '20',
        discard_limit: '180'
      });
    }
  },

  methods: {
    handleCancel() {
      this.isAgreed = false;
      this.$emit('close-disclaimer');
    },

    handleConfirm() {
      if (this.isAgreed) {
        this.$emit('confirm-disclaimer', this.postId);
      }
    }
  }
});
</script>
