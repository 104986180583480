<template>
    <phrozen-verified-disclaimer
      v-if="state.showDisclaimer"
      :show="state.showDisclaimer"
      :post-id="state.currentPostId"
      @close-disclaimer="handleCloseDisclaimer"
      @confirm-disclaimer="handleConfirmDisclaimer"
    />
    <modal
      v-if="state.showModal"
      :show="state.showModal"
      :title="$t('models.index.notice')"
      :content="$t('phrozen_verified.notice.not_be_edited')"
      :confirm-button-text="$t('phrozen_verified.disclaimer.confirm')"
      :confirmButtonClass="`btn-primary w-full`"
      @close-modal="handleCloseModal"
    />
  <div class="w-full flex flex-wrap h-full">
    <div class="flex-1 h-full">
      <template v-if="state.initialLoading">
        <div class='flex flex-col justify-center items-center w-full h-full'>
            {{ $t('models.index.loading') }}
        </div>
      </template>
      <template v-else-if="items.length === 0 && !state.hasPvModel">
        <div class='flex flex-col justify-center items-center w-full h-full'>
          <h3 class='text-24px font-bold'>
            {{ $t('models.phrozen_verified.upload_pv') }}
          </h3>
          <h3 class='text-24px font-bold'>
            {{ $t('models.phrozen_verified.maximize_exposure') }}
          </h3>
          <img src="../../images/increasing_income_chart.gif" class='w-40 h-40 my-8' />
          <h3 class='text-20px font-bold mb-2'>
            {{ $t('models.phrozen_verified.increasing_income') }}
          </h3>
          <ul class='list-disc marker:text-orange-10'>
            <li>
              <span class='font-bold text-orange-10'>{{ $t('models.phrozen_verified.free') }}</span>
              {{ $t('models.phrozen_verified.model') }}
              <span class='font-bold text-orange-10'>{{ $t('models.phrozen_verified.pre_support') }}</span>
              {{ $t('models.phrozen_verified.service') }}
            </li>
            <li>
              {{ $t('models.phrozen_verified.exposure_to_over') }}
              <span class='font-bold text-orange-10'>{{ $t('models.phrozen_verified.customer') }}</span>
              {{ $t('models.phrozen_verified.exposure_to_over_jp') }}
            </li>
            <li>
              {{ $t('models.phrozen_verified.enjoy') }}
              <span class='font-bold text-orange-10'>{{ $t('models.phrozen_verified.higher_sale_price') }}</span>
            </li>
          </ul>
          <a :href="CONFIG.ROUTES.NEW_MODEL" class='btn btn-primary inline-block min-w-40 rounded-[32px] my-8 lg:mb-0'>
            {{ $t('models.phrozen_verified.upload_pv') }}
          </a>
        </div>
      </template>
      <template v-else>
        <model-search-form @search="handleSearch" />
        <model-state-filter @filter="handleStateFilter" :pv-hint="true" :current-state="state.currentState" :state-types="state.postStateTypes" />
      

        <div class="mt-4 text-14px">
          <div class="grid grid-cols-[33fr_47fr_87fr_40fr]">
            <div class='h-5 text-grey-3 font-medium pl-2'>{{ $t(`models.index.thumbnail`) }}</div>
            <div class='h-5 text-grey-3 font-medium'>{{ $t(`models.index.model_title`) }}</div>
            <div class='h-5 text-grey-3 font-medium'>{{ $t(`models.phrozen_verified.detail`) }}</div>
            <div class='h-5 text-grey-3 font-medium text-center'>{{ $t(`models.index.status`) }}</div>
            <div class='h-5 text-grey-3 font-medium'></div>
            <hr class='col-span-5 border-grey-3 my-2'>
          </div>

          <div v-if="state.initialLoading" class="flex flex-col items-center justify-center aspect-[828/495]">
            <p class="text-16px font-medium text-center">
              {{ $t('models.index.loading') }}
            </p>
          </div>

          <div v-else-if="state.loading && items.length < 1" class="flex flex-col items-center justify-center aspect-[828/495]">
            <p class="text-16px font-medium text-center">
              {{ $t('models.index.loading') }}
            </p>
          </div>

          <div v-else-if="items && items.length === 0" class="flex flex-col items-center justify-center aspect-[828/495]">
            <img src="../../images/file.svg" class="block w-12 h-12 mb-4" />
            <p class="text-16px font-medium text-center">
              {{ $t('models.index.no_record_model') }}
            </p>
          </div>

          <div v-else class="aspect-[828/495]">
            <div class="model-container max-h-full h-full overflow-auto scrollbar pr-1 -mr-3"
                ref="modelContainer">
              <phrozen-verified-item
                v-for="(post, index) in items"
                :key="post.id"
                :post="post"
                :is-last="index === items.length - 1"
                :is-submitting="submittingPosts.has(post.id)"
                @post-in-progress="handlePostInProgress"
                @pv-post-cancel="handleCancelAction"
                @show-disclaimer="handleShowDisclaimer"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, reactive, computed, onMounted, onUpdated, nextTick} from "vue";
import debounce from 'lodash/debounce';

export default defineComponent({
  setup(props) {
    const CONFIG = {
      ITEMS_PER_PAGE: 8,
      SCROLL_THRESHOLD: 30,
      DEBOUNCE_DELAY: 100,
      DEFAULT_STATE: 'init',
      ROUTES: {
        NEW_MODEL: '/profile/models/adv_new',
        CURRENT_USER: '/api/v1/profiles/current_user',
        PV_MODELS: '/api/v1/profiles/pv_models',
      }
    };

    const modelContainer = ref(null);
    const currentUser = ref(null);
    const submittingPosts = ref(new Set());

    const state = reactive({
      loading: false,
      initialLoading: true,
      hasPvModel: false,
      currentSearch: '',
      currentState: CONFIG.DEFAULT_STATE,
      postStateTypes: [],
      showDisclaimer: false,
      currentPostId: null,
    });

    const itemsMap = reactive(new Map());

    const stateData = reactive({
      init: { items: [], totalCount: 0 },
      pending: { items: [], totalCount: 0 },
      progress: { items: [], totalCount: 0 },
      completed: { items: [], totalCount: 0 },
    });

    const pageInfo = reactive({
      init: { page: 0, hasMore: true },
      pending: { page: 0, hasMore: true },
      progress: { page: 0, hasMore: true },
      completed: { page: 0, hasMore: true },
     });

    const items = computed(() => stateData[state.currentState].items);

    // ===== API Request Methods =====
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch(CONFIG.ROUTES.CURRENT_USER, {
          credentials: 'include',
        });
        if (response.ok) {
          currentUser.value = await response.json();
        } else {
          throw new Error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchItemData = async (stateType, page, perPage) => {
      if (!pageInfo[stateType].hasMore || state.loading) return;

      state.loading = true;
      const params = new URLSearchParams({
        page: page + 1,
        per_page: perPage,
        kw: state.currentSearch,
        state: stateType
      });

      try {
        const response = await fetch(`${CONFIG.ROUTES.PV_MODELS}?${params}`);
        if (response.ok) {
          const data = await response.json();
          updatePaginationInfo(stateType, page, data.total_pages);

          if (data.data?.length) {
            categorizeItems(data.data, stateType, data.total_count);
          }
        }
      } catch (error) {
        console.error('Error fetching model data:', error);
        pageInfo[stateType].hasMore = false;
      } finally {
        state.loading = false;
        state.initialLoading = false;
      }
    };

    const fetchPostStateTypes = async () => {
      if (!currentUser.value) return;
      state.loading = true;
      try {
        const url = `/api/v1/profiles/${currentUser.value.username}/get_pv_post_state_types`;
        const params = new URLSearchParams({
          kw: state.currentSearch,
        });
        const response = await fetch(`${url}?${params}`);
        if (response.ok) {
          const data = await response.json();
          state.postStateTypes = data.post_state_types || [];
          if (!state.hasPvModel) {
            state.hasPvModel = data.post_state_types.reduce((total, type) => total + type.count, 0) > 1;
          }
        }
      } catch (error) {
        console.error('Error fetching post state types:', err);
      } finally {
        state.loading = false;
      }
    };

    // ===== Event Handling Methods =====
    const handleSearch = async (keyword) => {
      state.currentSearch = keyword;
      resetAllStates();
      await fetchItemData(state.currentState, 0, CONFIG.ITEMS_PER_PAGE);
      scrollToTop();
      fetchPostStateTypes();
      addScrollListener();
    };

    const handleStateFilter = async (stateType) => {
      if (state.currentState === stateType) return;
      state.currentState = stateType;
        if (stateData[stateType].items.length < CONFIG.ITEMS_PER_PAGE && pageInfo[stateType].hasMore) {
          await fetchItemData(stateType, pageInfo[stateType].page, CONFIG.ITEMS_PER_PAGE);
        }

      // 確保列表中的項目是最新的
      stateData[stateType].items = stateData[stateType].items.map(item => 
        itemsMap.get(item.id) || item
      );
      scrollToTop();
      addScrollListener();
    };

    const handleSubmitAction = async (postId) => {
      if (submittingPosts.value.has(postId)) return;
      
      submittingPosts.value.add(postId);

      try {
        const response = await fetch(`/profile/models/${postId}/ask_for_verified`, {
          method: 'POST',
          headers: getRequestHeaders(),
        });

        const result = await response.json();
        if (response.ok) {
          const oldState = itemsMap.get(postId)?.verified_state || 'init';
          updatePostInItems(result.data, oldState);
        } else {
          throw new Error(result.message || 'Submission failed');
        }
      } catch (error) {
        handleApiError('submitting', error);
      } finally {
        submittingPosts.value.delete(postId);
      }
    };

    const handleCancelAction = async (postId) => {
      if (submittingPosts.value.has(postId)) return;
      
      submittingPosts.value.add(postId);
  
      try {
        const response = await fetch(`/profile/models/${postId}/cancel_verified`, {
          method: 'POST',
          headers: getRequestHeaders(),
        });

        const result = await response.json();
        if (response.ok) {
          const oldState = itemsMap.get(postId)?.verified_state || 'pending';
          updatePostInItems(result.data, oldState);
        } else {
          throw new Error(result.message || 'Cancellation failed');
        }
      } catch (error) {
        handleApiError('cancelling', error);
      } finally {
        submittingPosts.value.delete(postId);
      }
    };

    const handleShowDisclaimer = async (postId) => {
      state.currentPostId = postId;
      state.showDisclaimer = true;
    };

    const handleCloseDisclaimer = async () => {
      state.showDisclaimer = false;
    };

    const handleConfirmDisclaimer = async (postId) => {
      state.currentPostId = postId;
      state.showDisclaimer = false;
      await handleSubmitAction(postId);
    };

    const handlePostInProgress = async (post) => {
      state.showModal = true;
    };

    const handleCloseModal = async () => {
      state.showModal = false;
    };

    // ===== Data Processing Methods =====
    const categorizeItems = (newItems, stateType, totalCount) => {
      stateData[stateType].totalCount = totalCount;

      newItems.forEach(item => {
        itemsMap.set(item.id, item);
        
        // 確保 item 只出現在正確的狀態列表中
        Object.keys(stateData).forEach(state => {
          const index = stateData[state].items.findIndex(
            existing => existing.id === item.id
          );
          
          if (state === stateType) {
            // 如果是當前狀態，且不存在則添加
            if (index === -1) {
              stateData[state].items.push(item);
            } else {
              // 更新現有項
              stateData[state].items[index] = item;
            }
          } else if (index !== -1) {
            // 如果在其他狀態列表中找到，則移除
            stateData[state].items.splice(index, 1);
          }
        });
      });
    };

    const updatePaginationInfo = (stateType, currentPage, totalPages) => {
      pageInfo[stateType].page = currentPage + 1;
      pageInfo[stateType].hasMore = pageInfo[stateType].page < totalPages;
    };

    const updatePostInItems = (updatedPost, oldState) => {
      if (!updatedPost || !updatedPost.id) {
        console.error('Invalid updated post data');
        return;
      }
      // 更新全局 Map
      itemsMap.set(updatedPost.id, updatedPost);
      // 從舊狀態移除
      if (oldState && stateData[oldState]) {
        const oldIndex = stateData[oldState].items.findIndex(
          item => item.id === updatedPost.id
        );
        if (oldIndex !== -1) {
          stateData[oldState].items.splice(oldIndex, 1);
          stateData[oldState].totalCount--;
        }
      }
      // 添加到新狀態
      const newState = updatedPost.verified_state;
      if (stateData[newState]) {
        const existingIndex = stateData[newState].items.findIndex(
          item => item.id === updatedPost.id
        );
        if (existingIndex === -1) {
          stateData[newState].items.unshift(updatedPost);
          stateData[newState].totalCount++;
        } else {
          stateData[newState].items[existingIndex] = updatedPost;
        }
      }

      updatePostStateCount(oldState, newState);
    };

    const updatePostStateCount = (oldState, newState) => {
      state.postStateTypes = state.postStateTypes.map(type => {
        let count = type.count;
        if (type.type === oldState) count--;
        if (type.type === newState) count++;

        return { ...type, count: Math.max(0, count) };
      });

      if (oldState !== null) {
        stateData[oldState].totalCount--;
      }
      if (newState !== null) {
        stateData[newState].totalCount++;
      }
    };

    const resetAllStates = () => {
      Object.keys(stateData).forEach(key => {
        stateData[key].items = [];
        stateData[key].totalCount = 0;
        pageInfo[key].page = 0;
        pageInfo[key].hasMore = true;
      });
    };


    const addScrollListener = () => {
      const modelContainer = document.querySelector('.model-container')
      if (modelContainer) {
        if (!modelContainer.hasAttribute('data-scroll-listener')) {
          modelContainer.addEventListener('scroll', handleScroll, { passive: true });
          modelContainer.setAttribute('data-scroll-listener', 'true');
        }
      } else {
        console.warn('Model container element not found');
      }
    };

    const handleScroll = debounce((event) => {
      if (!pageInfo[state.currentState].hasMore) return;

      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrolledPercentage > CONFIG.SCROLL_THRESHOLD && !state.loading) {
        fetchItemData(state.currentState, pageInfo[state.currentState].page , CONFIG.ITEMS_PER_PAGE);
      }
    }, CONFIG.DEBOUNCE_DELAY);
    
    // ===== Utility Methods =====

    const getRequestHeaders = () => ({
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    const handleApiError = (action, error) => {
      console.error(`Error ${action.toLowerCase()}ing post:`, error);
      alert(error.message);
    };

    const scrollToTop = () => {
      const modelContainer = document.querySelector('.model-container');
      if (modelContainer) {
        modelContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    onMounted(async () => {
      try {
        await fetchCurrentUser(); 
        if (currentUser.value) {
          await fetchPostStateTypes();
          await fetchItemData(state.currentState, 0, CONFIG.ITEMS_PER_PAGE);
          addScrollListener();
        } else {
          console.error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error in onMounted:', error);
      }
    });
    


    return {
      items,
      stateData,
      pageInfo,
      currentUser,
      state,
      CONFIG,
      submittingPosts,
      handleSearch,
      handleStateFilter,
      handleShowDisclaimer,
      handleCloseDisclaimer,
      handleConfirmDisclaimer,
      handleSubmitAction,
      handleCancelAction,
      handlePostInProgress,
      handleCloseModal
    };
  }
});
</script>

